import React from "react"
import socialLinks from "../../constants/social_links"
import styled from "styled-components"
import logo from "../../assets/images/Maivita_logo_120px.png";
import {Link} from "gatsby";
import {FaPhone, FaEnvelope, FaHome} from "react-icons/fa";

const FooterWrapper = styled.footer`
  background: var(--clr-maivita-green);
  display: grid;
  grid-template-columns: 250px repeat(2, 300px) auto;
  color: white;
  justify-content: space-around;
  font-size: 1.2em;
  padding: 10px 40px 50px 40px;

  div {
    margin-top: 30px;

    li {
      list-style: none;
      font-size: 1em;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;

    div {
      margin-top: 0;
    }
  }

  h4 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;  
    color: var (--clr-white);
    text-transform: uppercase;
    font-size: 1.3rem;
  }

  h4 span {
    color: var (--clr-primary-5);
  }

  .footer-links {
    margin: 0 auto 1rem auto;
  }

  .footer .social-link {
    color: var (--clr-white);
  }

  .footer .social-link:hover {
    color: var (--clr-primary-5);
  }

  a, p {
    color: white;
  }

  a:hover {
    color: var(--clr-primary-8);
  }
`

const Logo = styled.img`
  width: 100px;
  margin-top: 0.375rem;
`

const SocialLinks = styled.div`
  display: flex;
  font-size: 8rem;
`

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 48px;
  background: var(--clr-maivita-dark-green);

  a {
    color: white;
    margin-left: 6px;
  }
`

const Footer = () => {
    return (
        <>
            <FooterWrapper>
                <div>
                    <Link to="/">
                        <Logo src={logo} alt="maivita"/>
                    </Link>
                    <div>
                        <h4>Yhteystiedot</h4>
                        <p>Muntsilantie 19<br/> 14680 ALVETTULA, HÄMEENLINNA</p>
                        <p><a href="tel:+358400315069"><FaPhone/> 0400 315 069</a></p>
                        <p><a href="mailto:info@maivita.fi"><FaEnvelope/> info@maivita.fi</a></p>
                    </div>

                </div>

                <div>
                    <h4>Maivita Oy</h4>
                    <span>Kotoisa hoitokeskuksemme sijaitsee kauniissa ja luonnonläheisessä ympäristössä Vähäjärven rannalla.
                </span>
                    <div>
                        <h4>Arvomme ovat</h4>
                        <li>Avoimuus</li>
                        <li>Yhteistyö</li>
                        <li>Rehellisyys</li>
                        <li>Asiakkaan kunnioitus</li>
                    </div>
                </div>

                <div>
                    <h4>Valikko</h4>
                    <li><Link to="/"><FaHome /></Link></li>
                    <li><Link to="/paihderiippuvuus">Päihderiippuvuuden hoito</Link></li>
                    <li><Link to="/peliriippuvuus">Peliriippuvuuden hoito</Link></li>
                    <li><Link to="/alkoholismi">Alkoholismin hoito</Link></li>
                    <li><Link to="/laheisriippuvuus">Läheisriippuvuuden hoito</Link></li>
                    <li><Link to="/minnesota-hoito">Hoitoon tulo</Link></li>
                    <li><Link to="/laheisille">Läheisille</Link></li>
                    <li><Link to="/paihdepalveluita-jarjestaville">Päihdepalveluita järjestäville</Link></li>
                    <li><Link to="/kokemuksia-hoidosta">Asiakaskokemuksia</Link></li>
                    <li><Link to="/ajankohtaista">Ajankohtaista</Link></li>
                    <li><Link to="/yhteystiedot/henkilokuntamme">Henkilökuntamme</Link></li>
                    <li><Link to="/yhteystiedot/ota-yhteytta">Ota yhteyttä</Link></li>
                    <li><Link to="/info">Info in English</Link></li>
                </div>


                <div>

                    <div className="mb-3">
                        <h4>Uutta!</h4>
                        <li><Link to='/tyouupumus'>Hoitoa työuupumukseen</Link></li>
                    </div>

                    <div className="mb-3">
                        <h4>Katso kuvia</h4>
                        <li><Link to='/maivita-paihdehoitolaitos-kuvia'>Kuvagalleria</Link></li>
                    </div>

                    <h4>Seuraa meitä</h4>
                    <SocialLinks className="mb-3">
                        {socialLinks.map(link => {
                            return (
                                <a href={link.url} key={link.id} target="_blank" rel="noreferrer"
                                   className="social-link">
                                    {link.icon} &nbsp;
                                </a>
                            )
                        })}
                    </SocialLinks>

                    <div>
                        <h4>Tietosuoja</h4>
                        <a href='/Tietosuojaseloste_Maivita.pdf' target="_blank" rel="noreferrer">
                            Tietosuojaseloste
                        </a>
                    </div>

                </div>


            </FooterWrapper>
            <Banner>Maivita Oy {new Date().getFullYear()} &copy; powered by
                <strong>
                    <a href="https://halian.fi" target="_blank" rel="noreferrer">halian</a>
                </strong>
            </Banner>
        </>
    )
}

export default Footer
